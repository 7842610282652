import * as React from 'react';
import classNames from 'classnames';
import styles from './Navbar.module.scss';
import logoFull from '../../../assets/images/logo_full_purple.png';
import { Link } from 'gatsby';
import estateAgentsImg from '../../../assets/images/solutions/estateagents.svg';
import restaurantsImg from '../../../assets/images/solutions/restaurants.svg';
import hairBeautyImg from '../../../assets/images/solutions/hairbeauty.svg';
import accountantsImg from '../../../assets/images/solutions/accountants.svg';

export interface NavbarProps {}

const TOP_THRESHOLD = 20;

const scrollY = (w: Window) => (w ? w.scrollY : 0);

const Navbar: React.FunctionComponent<NavbarProps> = () => {
  const [mobileNav, setMobileNav] = React.useState(false);
  const [isTop, setTop] = React.useState(true);

  const minimise = () => setMobileNav(false);

  const handleScroll = () => {
    const isCurrentTop = scrollY(window) <= TOP_THRESHOLD;

    if (isTop !== isCurrentTop) {
      setTop(isCurrentTop);
    }
  };

  React.useEffect(() => {
    setTop(scrollY(window) <= TOP_THRESHOLD);
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  return (
    <div
      className={classNames(styles.Navbar, {
        [styles.Scrolling]: !isTop,
        [styles.MobileOpen]: mobileNav,
      })}
    >
      <nav className={styles.Nav} role='navigation' aria-label='Primary navigation'>
        <div className={styles.Brand}>
          <Link to='/'>
            <img src={logoFull} />
          </Link>
        </div>
        <ul className={styles.NavList}>
          <li className={styles.Expandable}>
            <a>
              Solutions <i className='fa fa-chevron-down' />
            </a>
            <ul className={styles.Drawer}>
              <li>
                <Link to='/solutions/estate-agents'>
                  <img src={estateAgentsImg} />
                  <div>
                    <span className={styles.For}>Opinous for</span>
                    Estate Agents
                  </div>
                </Link>
              </li>
              <li>
                <Link to='/solutions/restaurants'>
                  <img src={restaurantsImg} />
                  <div>
                    <span className={styles.For}>Opinous for</span>
                    Restaurants
                  </div>
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to='/features'>Features</Link>
          </li>
          <li>
            <Link to='/pricing'>Pricing</Link>
          </li>
          <li>
            <a href='https://developers.opinous.com' target='_blank'>
              Developers
            </a>
          </li>
        </ul>
        <ul className={styles.Actions}>
          <li>
            <div>
              <a href='https://manage.opinous.com/join' className={styles.Create}>
                Create Account
              </a>
            </div>
          </li>
          <li>
            <a href='https://manage.opinous.com/login'>Login</a>
          </li>
        </ul>
        <a className={styles.MobileButton} onClick={() => setMobileNav(!mobileNav)}>
          <i className={mobileNav ? 'far fa-times fa-fw' : 'far fa-bars fa-fw'} />
        </a>
      </nav>
      <ul className={styles.MobileNav}>
        <li>
          <Link to='/solutions/estate-agents' onClick={minimise}>
            Estate Agents
          </Link>
        </li>
        <li>
          <Link to='/solutions/restaurants' onClick={minimise}>
            Restaurants
          </Link>
        </li>
        <li>
          <Link to='/features' onClick={minimise}>
            Features
          </Link>
        </li>
        <li>
          <Link to='/pricing' onClick={minimise}>
            Pricing
          </Link>
        </li>
        <li>
          <a href='https://developers.opinous.com' target='_blank'>
            Developers
          </a>
        </li>
        <li className={styles.MobileAction}>
          <a href='https://manage.opinous.com/login' className={styles.Login}>
            Login
          </a>
        </li>
        <li className={styles.MobileAction}>
          <a href='https://manage.opinous.com/join'>Create Account</a>
        </li>
      </ul>
      <div className={styles.MobileNavBackdrop} />
    </div>
  );
};

export default Navbar;
