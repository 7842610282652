import * as React from 'react';
import styles from './index.module.scss';
import Navbar from '../../components/Navbar';
import Head from '../../components/Head';
import Footer from '../../components/Footer';
import LearnMore from '../../components/LearnMore';
import Hero from '../../components/Hero';
import heroImage from '../../../assets/images/solutions/accountants.svg';

const UseCaseAccountants: React.FunctionComponent<{}> = () => {
  return (
    <div className={styles.Solution}>
      <Head title='Opinous for Accountants' />
      <Navbar />
      <Hero graphic={heroImage} narrow={true}>
        <h1>
          <span className={styles.For}>Opinous For</span> Accountants
        </h1>
        <h2>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce mollis eros est, vitae
          placerat neque varius ac. Morbi sollicitudin elit sollicitudin turpis congue mollis.
        </h2>
      </Hero>
      <LearnMore />
      <Footer />
    </div>
  );
};

export default UseCaseAccountants;
