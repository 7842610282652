import * as React from 'react';
import classNames from 'classnames';
import styles from './Hero.module.scss';

interface HeroProps {
  graphic: string;
  children: any;
  actions?: boolean;
  narrow?: boolean;
}

const Hero: React.FunctionComponent<HeroProps> = (props) => {
  return (
    <div className={styles.Hero}>
      <div className={classNames(styles.Content, { [styles.Narrow]: props.narrow })}>
        <div className={styles.Left}>
          <div>
            {props.children}
            {props.actions ? (
              <div className={styles.CTAs}>
                <a href='https://manage.opinous.com/join'>Create an account</a>
                <a className={styles.Faded} href='https://manage.opinous.com/login'>
                  Login
                </a>
              </div>
            ) : null}
          </div>
        </div>
        <div className={styles.Right}>
          <img src={props.graphic} />
        </div>
      </div>
      <i className='far fa-chevron-down' />
      <svg className={styles.Crop} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 48'>
        <path
          fill='#FFF'
          fillRule='evenodd'
          d='M0 0c194.22 23.31 345.236 37.383 453.045 42.218C539.007 46.073 627.992 48 720 48c265.097 0 505.097-16 720-48v48H0V0z'
        />
      </svg>
    </div>
  );
};

export default Hero;
