import * as React from 'react';
import styles from './LearnMore.module.scss';

const LearnMore: React.FunctionComponent<{}> = () => {
  return (
    <section className={styles.LearnMore}>
      <svg className={styles.Crop} viewBox='0 0 1440 48' version='1.1'>
        <g id='bottom-concave' transform='translate(0.000000, -436.000000)'>
          <g fill='white' fillRule='nonzero'>
            <path
              d='M1440,484 C1225.09668,452 985.09668,436 720,436 C627.991932,436 539.007013,437.927362 453.045242,441.782085 C345.235831,446.616507 194.22075,460.689145 0,484 L0,436 L1440,436 L1440,484 Z'
              id='background-circular-shape-2'
            />
          </g>
        </g>
      </svg>
      <div className={styles.Content}>
        <h3>Interested in learning more?</h3>
        <p>
          Opinous can be setup in under five minutes with no technical support required and is free
          to try for the first two weeks!
        </p>
        <a href='https://manage.opinous.com/join'>Get Started</a>
        <a onClick={() => (window as any).Intercom('show')} className={styles.Faded}>
          Book a Demo
        </a>
      </div>
    </section>
  );
};

export default LearnMore;
