import * as React from 'react';
import styles from './Footer.module.scss';
import logoFull from '../../../assets/images/logo_full_white.png';
import { Link } from 'gatsby';

export interface FooterProps {}

const Footer: React.FunctionComponent<FooterProps> = () => {
  return (
    <div className={styles.Footer}>
      <div className={styles.Content}>
        <div className={styles.Logo}>
          <img src={logoFull} />
        </div>
        <nav className={styles.Links} role='navigation' aria-label='Footer navigation'>
          <ul>
            <li>Product</li>
            <li>
              <Link to='/features'>Features</Link>
            </li>
            <li>
              <Link to='/pricing'>Plans & Pricing</Link>
            </li>
          </ul>
          <ul>
            <li>Solutions</li>
            <li>
              <Link to='/solutions/estate-agents'>Opinous for Estate Agents</Link>
            </li>
            <li>
              <Link to='/solutions/restaurants'>Opinous for Restaurants</Link>
            </li>
          </ul>
          <ul>
            <li>Developers</li>
            <li>
              <a href='https://developers.opinous.com/reference/tags/displaykit' target='_blank'>Drop-In Review Display Kit</a>
            </li>
            <li>
              <a href='https://developers.opinous.com/reference/tags/searchkit' target='_blank'>Drop-In Search Metadata Kit</a>
            </li>
            <li>
              <a href='https://developers.opinous.com/reference/rest-api' target='_blank'>REST API Reference</a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Footer;
